@font-face {
    font-family: 'icomoon';
    src:  url('/public/icons/fonts/icomoon.eot?jngvjp');
    src:  url('/public/icons/fonts/icomoon.eot?jngvjp#iefix') format('embedded-opentype'),
      url('/public/icons/fonts/icomoon.ttf?jngvjp') format('truetype'),
      url('/public/icons/fonts/icomoon.woff?jngvjp') format('woff'),
      url('/public/icons/fonts/icomoon.svg?jngvjp#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }



  @font-face {
    font-family: 'icomoon';
    src:  url('/public/icons/fonts/icomoon.eot?jngvjp');
    src:  url('/public/icons/fonts/icomoon.eot?jngvjp#iefix') format('embedded-opentype'),
      url('/public/icons/fonts/icomoon.ttf?jngvjp') format('truetype'),
      url('/public/icons/fonts/icomoon.woff?jngvjp') format('woff'),
      url('/public/icons/fonts/icomoon.svg?jngvjp#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  
  .icon-hand:before {
    content: "\e91c";
    color: #acaeb3;
  }
  .icon-participants:before {
    content: "\e919";
    color: #acaeb3;
  }
  .icon-message-send .path1:before {
    content: "\e91a";
    color: rgb(23, 109, 238);
  }
  .icon-message-send .path2:before {
    content: "\e91b";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .icon-mic-disable-white:before {
    content: "\e918";
    color: #c1c1c6;
  }
  .icon-kick:before {
    content: "\e917";
    color: #c1c1c6;
  }
  .icon-screen-enable:before {
    content: "\e908";
    color: #c5c6c9;
  }
  .icon-plus:before {
    content: "\e905";
    color: #c1c1c6;
  }
  .icon-screen-disable:before {
    content: "\e900";
    color: #c5c6c9;
  }
  .icon-cam-enable:before {
    content: "\e901";
    color: #c5c6c9;
  }
  .icon-cam-disable:before {
    content: "\e902";
    color: #f00;
  }
  .icon-cam-disable-white:before {
    content: "\e902";
    color: #c1c1c6;
  }
  .icon-message:before {
    content: "\e903";
    color: #c5c6c9;
  }
  .icon-mic-enable:before {
    content: "\e904";
    color: #c1c1c6;
  }
  .icon-mic-disable:before {
    content: "\e906";
    color: #f00;
  }
  .icon-screen-disable-red:before {
    content: "\e907";
    color: #f00;
  }
  .icon-arrow-right:before {
    content: "\e909";
    color: #c1c1c6;
  }
  .icon-arrow-left:before {
    content: "\e90a";
    color: #c1c1c6;
  }
  .icon-settings:before {
    content: "\e90b";
    color: #c5c6c9;
  }
  .icon-call-end:before {
    content: "\e90c";
    color: #f00;
  }
  .icon-grid:before {
    content: "\e90d";
    color: #c5c6c9;
  }
  .icon-voice-enable:before {
    content: "\e90e";
    color: #c5c6c9;
  }
  .icon-voice-disable:before {
    content: "\e90f";
    color: #f00;
  }
  .icon-observer-disable:before {
    content: "\e910";
    color: #c5c6c9;
  }
  .icon-observer-enable:before {
    content: "\e911";
    color: #f00;
  }
  .icon-invite:before {
    content: "\e912";
    color: #c5c6c9;
  }
  .icon-chat-close:before {
    content: "\e913";
    color: #c5c6c9;
  }
  .icon-chat-open:before {
    content: "\e914";
    color: #c5c6c9;
  }
  .icon-reverse:before {
    content: "\e915";
    color: #c5c6c9;
  }
  .icon-pin:before {
    content: "\e916";
    color: #c5c6c9;
  }
  