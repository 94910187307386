body {
    overflow: hidden;
}

.ant-list-item-meta-description {
    width: 180px;
    max-width: 250px;
    background-color: #1B1E2B;
    border-radius: 0 15px 15px 10px;
    color: #A0A1A8 !important;
    padding: 2px 5px;
}

.chat-auth-users .ant-list-item {
    color: hsl(233, 4%, 64%) !important;
    float: right;
}

.chat-auth-users .messagebox {
    padding: 4px 4px 4px 14px !important;
    min-height: 25px;
    height: auto !important;
    font-size: 14px;
    width: 200px;
    max-width: 250px;
    background-color: #2766E6;
    border-radius: 15px 0 15px 15px;
    color: white !important;
    display: inline-block;
    text-align: left;
    margin: 0;
    overflow-wrap: break-word;
}

.chat-auth-users .hour {
    margin-right: 45px;
}

.chat-auth-users .messageboxp {
    font-size: 12px !important;
    margin: 0 !important;
    overflow-wrap: break-word;
}

.chat-auth-users .messagebox-whisper {
    min-height: 25px;
    height: auto !important;
    width: 200px;
    max-width: 250px;
    background-color: #071a3f;
    border-radius: 15px 0 15px 15px;
    color: #cfcfcf !important;
    padding: 2px 5px;
    display: inline-block;
    text-align: left;
    margin: 0;
    overflow-wrap: break-word;
}



.chat-auth-users .messageboxp-whisper {
    font-size: 12px !important;
    margin: 0 !important;
    overflow-wrap: break-word;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu {
    background-color: #1B1E2B !important;
    color: #ffffff !important;
}

.welcome-modal {
    height: 100vh !important;
}

.welcome-modal .ant-modal-content {
    background-color: rgb(41, 43, 59);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    box-shadow: none;
}

.welcome-modal .ant-modal-title {
    background-color: rgb(41, 43, 59);
}

.welcome-modal .ant-modal-header {
    background-color: rgb(41, 43, 59);

}

#modalCamera video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
}

#modalCamerax video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
}

.welcome-modal-switch {
    border: 0.1px solid white;
}

/* .ant-list-item-meta-description{
    max-width: 250px;
    min-width: 180px;
    background-color: #1B1E2B;
    border-radius: 15px 0px 15px 15px;
    float: right;
    color: #A0A1A8!important;
    padding: 2px 5px;
}
.ant-list-item-meta-avatar{
    float: right;
    text-align: right;
    justify-content: end;;
} */
.hour {
    margin-left: 32px;
    font-size: 10px;
}

.chat-users .ant-list-item {
    color: hsl(233, 4%, 64%) !important;
    float: left;
    margin-bottom: 10px !important;
}

.avatar-char {
    font-size: 16px;
    color: hsl(233, 4%, 64%) !important;
}

.chat-users .messagebox {
    padding: 4px 4px 4px 14px !important;
    min-height: 25px;
    height: auto !important;
    font-size: 14px;
    width: 200px;
    max-width: 250px;
    background-color: #1B1E2B;
    border-radius: 0px 15px 15px 15px;
    color: white !important;
    padding: 2px 5px;
    display: block;
    text-align: left;
    margin-left: 40px;
    margin-right: 4px;
    margin-bottom: 0;
    overflow-wrap: break-word;
    margin-top: 0 !important;
}

.chat-users .hour {
    margin-left: 45px;
}

.chat-users .messageboxp {
    margin: 0 !important;
}

.chat-users .name-surname {
    display: inline-block;
    text-align: left;
    margin-bottom: 0;
}


.chat-users .messagebox-whisper {
    min-height: 25px;
    height: auto !important;
    width: 200px;
    max-width: 250px;
    background-color: #00051b;
    border-radius: 0px 15px 15px 15px;
    color: #cfcfcf !important;
    padding: 2px 5px;
    display: block;
    text-align: left;
    margin-left: 45px;
    margin-bottom: 0;
    overflow-wrap: break-word;
}

.chat-users .messageboxp-whisper {
    margin: 0 !important;
}

.chat-users .name-surname-whisper {
    margin-left: 10px;
    display: contents;
    text-align: left;
    margin-bottom: 0;
    text-overflow: ellipsis;
    height: 2em;
    cursor: pointer;

}

.text-bold {
    font-weight: bold;
}





.ant-list-item {
    list-style-type: none;
}

.ant-list-item {
    list-style-type: none;
}

.icon-height {
    width: 18px !important;
    height: 18px !important;
    font-size: 18px !important;
    justify-content: center !important;
    align-items: center !important;
    display: flex;
    cursor: pointer;
}

.user-card {
    width: 307px !important;
    height: 45px !important;
    margin-bottom: 15px !important;
}

.user-card-box {
    width: 200px !important;
    padding-right: 10px;
}

.title-text {
    text-overflow: ellipsis;
    height: 2em;
    cursor: pointer;
    white-space: normal;
    padding-right: 10px;
}

.invite-user-card {
    width: 265px !important;
    height: 45px !important;
}

.invite-user-card-box {
    width: 200px !important;
    height: 45px !important;
}

.all-user-card {
    background-color: #292b3b;
    width: 307px !important;
    height: 45px !important;
    color: #A0A1A8 !important;
}

.all-user-card-box {
    width: 200px !important;
    padding-right: 10px;
}

.chat-auth-item {
    text-align: right !important;
}

.chat-user-item {
    text-align: left !important;
}

.return-button {
    float: right;
}

.action-bar {
    width: 702px;
}

.action-bar span:hover {
    cursor: pointer;
}

.ant-tabs-nav-operations {
    display: none !important;
}

.icon-action-bar {
    height: 52px !important;
    width: 52px !important;
    font-size: 30px !important;
    border: 2px solid #3E3E3E;
    border-radius: 15%;
    justify-content: center !important;
    align-items: center !important;
    display: flex;
}

.icon-action-bar-settings {
    height: 52px !important;
    width: 52px !important;
    font-size: 12px !important;
    border: 2px solid #3E3E3E;
    border-radius: 15%;
    justify-content: center !important;
    align-items: center !important;
    display: flex;
}

.icon-action-bar-call {
    height: 52px !important;
    width: 78px !important;
    font-size: 16px !important;
    border: 2px solid #3E3E3E;
    border-radius: 15%;
    justify-content: center !important;
    align-items: center !important;
    display: flex;
}


.name-tag {
    /* background-color: #3E3E3E; */
    background: rgba(62, 62, 62, .3);
    /*  10% opaque green */
    padding: 5px 7px;
    width: 150px;
    border-radius: 10px;
    margin-bottom: 5px;
    z-index: 99;

}

.name-tag-title {
    color: white;
    font-size: 10px;
    opacity: 1 !important;
}

.screen-container {
    /* background-image: url('https://i.hizliresim.com/nvrp5qz.jpeg'); */
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 3px;
    display: inline-block;
    width: 96%;
    height: 95% !important;
    border: 1px solid #000;
    margin-left: 10px;
}

video {
    border-radius: 10px;
}

.screen-content video {
    border-radius: 8px;
    width: 100% !important;
}

.screen-container-3 {
    /* background-image: url('https://i.hizliresim.com/nvrp5qz.jpeg'); */
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 3px;
    display: inline-block;
    width: 96%;
    height: 96%;
    border: 1px solid #000;
    margin-left: 10px;
}

.screen {
    display: flex;
    position: relative;
    height: 100%;
    width: auto;
}

.title-camera-container {
    display: flex;
    justify-content: space-between;
    align-items: end;
    align-self: flex-end;
}

.grid-screens {
    border-radius: 10px;
    background: #1B1E2B;
}

.cell {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    background-color: gray;
    cursor: pointer;
}

.full-screen {
    position: fixed;
    height: calc(100vh - 150px);
    left: 25px;
    border-radius: 10px;
    top: 60px;
    width: calc(100vw - 375px);
    z-index: 999;
    background-color: #1b1e2b;
}

.full-screen-collapsed {
    position: fixed;
    height: calc(100vh - 150px);
    width: calc(100vw - 40px);
    left: 25px;
    border-radius: 10px;
    top: 60px;
    z-index: 999;
    background-color: #1b1e2b;
}


.cam-full video {
    height: 250px !important;
    border-radius: 7px;
    object-fit: fill;
}

.cam-full-collapsed video {
    height: 180px !important;
    border-radius: 7px;
    object-fit: fill;
}

.screen video {
    height: 100%;
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: 0 !important;
    max-width: 100%;
}

.title-camera-container {
    display: flex;
    justify-content: space-between;
    align-items: end;
    align-self: flex-end;
}

.screen-3 video {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: fill;
    z-index: 0;
    top: 0;
}

.cam {
    height: 120px;
    border-radius: 7px;
    z-index: 0;
}

.cam-collapsed {
    height: 120px;
}

.cam video {
    height: 120px;
    width: auto;
    border-radius: 7px;
    z-index: 0;

}

.icon-change {
    height: 24px;
    width: 24px;
    position: absolute;
    right: 0;
    z-index: 99;
}

.icon-change-collapsed {
    height: 24px;
    width: 24px;
    position: absolute;
    right: 0;
    z-index: 99;
}


.cam-collapsed video {
    height: 100px;
    border-radius: 7px;
}


.cam-4 video {
    height: 110px;
    width: auto;
    border-radius: 7px;
    background-position: bottom !important;
}

.cam-6 video {
    height: 90px;
    width: auto;
    border-radius: 7px;
    background-position: bottom !important;
}

.cam-6-collapsed video {
    height: 70px;
    border-radius: 7px;
    object-fit: fill;
}

.cam-10 video {
    height: 80px;
    width: auto;
    border-radius: 7px;
    background-position: bottom !important;
}

.cam-10-collapsed video {
    height: 70px;
    width: auto;
    border-radius: 7px;
    background-position: bottom !important;
}

.ant-card {
    border: 0 !important;
}

.ant-card-body {
    padding: 3px 5px !important;
}

.ant-card-meta-detail,
.ant-card-meta-avatar {
    display: flex;
    align-items: center;
    padding-inline-end: 5px !important;
}

.ant-card-body .ant-col {
    display: flex;
    align-items: center;
}

/* endMert */
.icon-chat-open {
    float: right;
    margin-right: 5rem;
    margin: 1.5rem 5rem auto 0;
}

.icon-chat-close {
    float: right;
    margin-right: 5rem;
    margin: 1.5rem 5rem auto 0;
}


.message-input {
    padding-right: 44px;
    background-color: #2F3643 !important;
    overflow: hidden;
}

.message-input input {
    background-color: #2F3643 !important;
}

.messages-box {
    height: calc(100vh - 190px) !important;
    width: 100%;
    overflow: auto;
}


/*scrol bar */
.messages-box::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
    background-color: #2F3643;
}

.messages-box::-webkit-scrollbar {
    width: 4px;
    background-color: #2F3643;
}

.messages-box::-webkit-scrollbar-thumb {
    background-color: #F5F5F5;
    border: 2px solid #F5F5F5;
}

.ant-popover-inner {
    background-color: #2F3643 !important;
    color: #FFF !important;
}

.ant-popover-inner .ant-popover-title {
    color: #FFF !important;
}

.ant-popconfirm-message {
    color: #FFF !important;
}

.ant-popconfirm-description {
    color: #FFF !important;
}

.ant-popover-inner .input-link {
    background-color: #2F3643 !important;
    border: 1px solid #2c2d2f;
    color: #FFF;
}

.ant-popover {
    --antd-arrow-background-color: #2F3643 !important;
}

.swtich-button-true {
    position: absolute;
    right: 25%;
    top: 0.3rem;
    z-index: 999;
    z-index: 999;
}

.swtich-button-false {
    position: absolute;
    top: 1.5rem;
    right: 22rem;
    z-index: 999;
}

.chat-span {
    color: #A0A1A8;
    font-size: 18px;
}

.chat-span:hover {
    color: #2766E6;
}

.participants-span {
    color: #A0A1A8;
    font-size: 16px;
}

.participants-span:hover {
    color: #2766E6;
}

.icon-sider {
    width: 22px !important;
    height: 16px !important;
    font-size: 18px !important;
}

.userPanel {
    width: 100%;
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;
}

.icon-message-send {
    font-size: 40px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 6px;

}

.ant-input::placeholder {
    color: #9DA4AF;
}

.content-screens {
    display: block;
}

.content {
    position: relative;
    flex-direction: column;
    height: calc(100vh - 150px);
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
}

.send-box {
    margin-top: 5px;
    position: sticky;
}

.send-button {
    margin-left: -3rem;
    z-index: 9999;
}

.send-button:hover {
    cursor: pointer;
}

/* .content video {
    display: inline-block;
    height: 9rem;
} */

#dact-container-main {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 9999999;
    display: flex;
    flex-direction: column;
    width: 250px;
}

#dact-container-main>div {
    background-color: rgba(0, 0, 0, 0.15);
    color: white;
    width: 100%;
    margin-bottom: 10px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px;
    animation: slide-in 0.2s;
    transition: all 0.3s ease;
}

.dact-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@keyframes slide-in {
    from {
        transform: translateX(30px);
        opacity: 0;
    }

    to {
        transform: translateX(0px);
        opacity: 1;
    }
}

button {
    background: none;
    border: none;
    color: #ffffff;
    font-weight: 900;
    outline: none;
    cursor: pointer;
    border-radius: 8px;
    padding: 4px 8px;
}

button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

